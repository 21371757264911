<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-toolbar flat color="primary" dark height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                    <v-spacer />
                    <v-chip small color="green" dark v-if="curso.status === 't'"><v-icon left small>mdi-check-circle</v-icon> Ativo</v-chip>
                    <v-chip small color="red" dark v-else><v-icon left small>mdi-close-circle</v-icon> Inativo</v-chip>
                    <v-btn v-if="permissao('curso_abrir')" exact :to="`/curso/alterar/${id_curso}`" icon class="py-4"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn v-if="permissao('curso_excluir')" exact @click="del" icon class="py-4"><v-icon>mdi-delete</v-icon></v-btn>
                    <v-btn to="/curso" exact icon><v-icon>mdi-arrow-left</v-icon></v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Curso:</strong> {{curso.curso}}</p>
                            <p class="text--primary mb-2"><strong>ID:</strong> {{curso.id_curso}}</p>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="text--primary mb-2"><strong>Disciplina:</strong> {{curso.disciplina}}</p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card>
                <v-toolbar flat color="primary" dark><v-toolbar-title>Conteúdos</v-toolbar-title></v-toolbar>
                
                <v-tabs vertical left>
                    <v-tab v-for="(c, i) in curso.conteudo" style="justify-content: left; text-transform: unset!important;" :key="i"><v-icon left>mdi-book-open-page-variant</v-icon>{{c.conteudo}}</v-tab>
                    <v-btn color="primary" text @click="dialogConteudoCadastrar = true"><v-icon left>mdi-plus</v-icon>Novo conteúdo</v-btn>
                    <v-tab-item v-for="(c, i) in curso.conteudo" :key="i">
                        <v-card flat>
                            <v-card-title>
                                {{c.conteudo}}
                                <v-spacer/>
                                <v-btn icon right @click="dialogConteudoEditarAbrir({id_conteudo : c.id_conteudo, conteudo : c.conteudo, livro : c.livro})"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn icon right @click="conteudoExcluir(c.id_conteudo)"><v-icon>mdi-delete</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col v-for="(l, i) in c.levels" :key="i" cols="12" md="6" xl="4">
                                        <v-card>
                                            <v-toolbar flat>
                                                <v-toolbar-title class="py-4">{{l.level}}</v-toolbar-title>
                                                <v-spacer />
                                                <v-btn @click="dialogLivroAbrir({id_conteudo : parseInt(c.id_conteudo), id_level : parseInt(l.id_level), arquivo : l.livro_link, epub : l.livro_epub})" icon class="py-4"><v-icon>mdi-book</v-icon></v-btn>
                                                <v-btn @click="dialogFraseCadastrarAbrir({id_conteudo : parseInt(c.id_conteudo), id_level : parseInt(l.id_level)})" icon class="py-4"><v-icon>mdi-plus</v-icon></v-btn>
                                            </v-toolbar>
                                            <v-divider/>
                                            <v-list>
                                                <div v-for="(f, ifr) in l.frases" style="background-color: #FFFFFF;" :key="ifr">
                                                    <v-list-item @click="false">
                                                        <v-list-item-content @click="dialogFraseAlterarAbrir(f)">
                                                            <v-list-item-title style="text-overflow: unset; white-space: unset;">{{f.frase}}</v-list-item-title>
                                                            <v-list-item-subtitle style="text-overflow: unset; white-space: unset;">{{f.resposta}}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-list-item-action><v-btn icon @click="fraseExcluir(f.id_frase)"><v-icon>mdi-delete</v-icon></v-btn></v-list-item-action>
                                                    </v-list-item>
                                                    <v-divider/>
                                                </div>
                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </v-col>

        <!--<v-col cols="12">
            <v-tabs v-model="tab" class="elevation-3" show-arrows dark background-color="primary">
                <v-tab>* Aulas</v-tab>
                <v-tab>* Alunos</v-tab>
                <v-tab>* Turmas</v-tab>
                <v-tab>* Professores</v-tab>
                <v-tab>* Franquias</v-tab>
                <v-tab>* Vocabulário</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="elevation-3">
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><AulaListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><AlunoListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><TurmaListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><UsuarioListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text><FranquiaListar/></v-card-text>
                </v-tab-item>
                <v-tab-item>
                    <v-divider/>
                    <v-card-text>/* Listar palavras buscadas da disciplina em questão */</v-card-text>
                </v-tab-item>
            </v-tabs-items>
        </v-col>-->

        <!-- Novo conteúdo -->
        <v-dialog width="500" v-model="dialogConteudoCadastrar">
            <v-card>
                <v-card-title>Novo conteúdo</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-text-field hide-details label="* Conteúdo:" v-model="registro.conteudo" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn text @click="conteudoCadastrar()">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Alterar conteúdo -->
        <v-dialog width="500" v-model="dialogConteudoEditar">
            <v-card>
                <v-card-title>Alterar conteúdo</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-text-field hide-details label="* Conteúdo:" v-model="registro.conteudo" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn text @click="conteudoAlterar()">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Nova frase -->
        <v-dialog width="700" v-model="dialogFraseCadastrar">
            <v-card>
                <v-card-title>Nova frase</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-textarea hide-details label="* Frase:" v-model="registro.frase" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="* Resposta:" v-model="registro.resposta" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn text @click="fraseCadastrar()">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Alterar frase -->
        <v-dialog width="700" v-model="dialogFraseEditar">
            <v-card>
                <v-card-title>Alterar frase</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-textarea hide-details label="* Frase:" v-model="registro.frase" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="* Resposta:" v-model="registro.resposta" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn text @click="fraseEditar()">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Link Livro -->
        <v-dialog width="700" v-model="dialogLivro">
            <v-card>
                <v-progress-linear :indeterminate="fileUploadProgress === 0" v-model="fileUploadProgress" absolute color="secondary" :active="carregando" />
                <v-card-title>Livro</v-card-title>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12"><v-select :disabled="carregando" hide-details label="* EPUB:" v-model="registro.epub" :items="[
                                {value : true, text : 'Sim'},
                                {value : false, text : 'Não'}
                            ]" /></v-col>
                            <v-col cols="12" v-if="!registro.epub"><v-text-field :disabled="carregando" hide-details label="* Link Adobe:" v-model="registro.arquivo" /></v-col>
                            <v-col cols="12" v-if="registro.epub"><v-file-input :disabled="carregando" @change="livroAdicionarArquivo" hide-details label="* Livro em EPUB:" v-model="registro.arquivo_placeholder" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
                <v-card-actions><v-spacer/><v-btn :loading="carregando" text @click="livroEditar()">Salvar</v-btn></v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>

/*
import AulaListar from "@/Views/Aula/Component/AulaListar"
import AlunoListar from "@/Views/Aluno/Component/AlunoListar"
import TurmaListar from "@/Views/Turma/Component/TurmaListar"
import UsuarioListar from "@/Views/Usuario/Component/UsuarioListar"
import FranquiaListar from "@/Views/Franquia/Component/FranquiaListar"*/
import {mapState} from "vuex"
import axios from "axios"

export default {
    name: "CursoGerenciar",
    // components: {FranquiaListar, UsuarioListar, TurmaListar, AlunoListar, AulaListar},
    props : ['id_curso'],
    data() {
        return {
            dialogConteudoEditar : false,
            dialogConteudoCadastrar : false,
            dialogFraseEditar : false,
            dialogFraseCadastrar : false,
            dialogLivro : false,
            tab : null,
            tabConteudo : null,
            registro : {},
            curso : {},
            carregando : false,
            file : null,
            fileUploadProgress : 0,

            selectDisc : [],
            erro : null,
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl'])
    },
    methods : {
        get() {
            return axios.post(`${this.baseUrl}curso/get`, {id_curso : this.id_curso, full : true}).then( (res) => {
                this.curso = res.data
            })
        },
        del() {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}curso/delete`, {id_curso : this.id_curso}).then( (res) => {

                    if (res.data) {

                        this.$router.push({path:'/curso'})
                    } else {

                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        conteudoExcluir(id_conteudo) {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}curso/conteudo_excluir`, {id_conteudo : id_conteudo}).then( (res) => {

                    if (res.data) {

                        this.get()
                    } else {

                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        fraseExcluir(id_frase) {
            let c = confirm("Essa ação não pode ser desfeita, tem certeza que deseja excluir?")
            if(c) {
                return axios.post(`${this.baseUrl}curso/frase_excluir`, {id_frase : id_frase}).then( (res) => {

                    if (res.data) {

                        this.get()
                    } else {

                        alert("Erro ao excluir registro")
                    }
                })
            }
        },
        conteudoCadastrar() {
            this.erro = null
            return axios.post(`${this.baseUrl}curso/conteudo_cadastrar`, {id_curso : this.id_curso, ...this.registro}).then((res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                } else {
                    this.registro = {}
                    this.dialogConteudoCadastrar = false
                    this.get()
                }
            })
        },
        conteudoAlterar() {
            this.erro = null
            return axios.post(`${this.baseUrl}curso/conteudo_editar`, this.registro).then((res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                } else {
                    this.registro = {}
                    this.dialogConteudoEditar = false
                    this.get()
                }
            })
        },
        fraseCadastrar() {
            this.erro = null
            return axios.post(`${this.baseUrl}curso/frase_cadastrar`, this.registro).then((res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                } else {
                    this.registro = {}
                    this.dialogFraseCadastrar = false
                    this.get()
                }
            })
        },
        fraseEditar() {
            this.erro = null
            return axios.post(`${this.baseUrl}curso/frase_editar`, this.registro).then((res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                } else {
                    this.registro = {}
                    this.dialogFraseEditar = false
                    this.get()
                }
            })
        },
        livroAdicionarArquivo(e) {
            this.file = e;
        },
        async enviarArquivoPorStreaming() {
            this.carregando = true
            const chunkSize = 16 * 1024 * 1024; // Tamanho do chunk: 16MB
            const totalChunks = Math.ceil(this.file.size / chunkSize)
            let chunkIndex = 0;
            while (chunkIndex < totalChunks) {
                const start = chunkIndex * chunkSize;
                const end = Math.min(start + chunkSize, this.file.size)
                const chunk = this.file.slice(start, end);

                const formData = new FormData();
                formData.append('arquivo', chunk);
                formData.append('json', JSON.stringify({
                    ...this.registro,
                    id_conteudo         : this.registro.id_conteudo,
                    id_level            : this.registro.id_level,
                    id_curso            : parseInt(this.id_curso),
                    arquivo_type        : this.file.type,
                    arquivo_chunk_size  : this.file.size,
                    arquivo_chunk_index : chunkIndex,
                    arquivo_chunk_total : totalChunks
                }));
                await axios.post(`${this.apiUrl}curso/livro/arquivo/alterar`, formData)
                chunkIndex++
                this.fileUploadProgress = (chunkIndex / totalChunks) * 100
            }

            this.fileUploadProgress = 0; // Upload completo
            this.carregando = false
            this.dialogLivro = false
            this.get()
        },
        async livroEditar() {
            this.erro = null
            this.carregando = true
            await axios.post(`${this.apiUrl}curso/livro/editar`, {
                ...this.registro,
                id_curso : parseInt(this.id_curso)
            }).then(() => {
                if(this.registro.epub) {
                    this.enviarArquivoPorStreaming()
                } else {
                    this.carregando = false
                    this.dialogLivro = false
                    this.registro = {}
                    this.get()
                }
            }).catch((e) => {
                this.erro = this.erroFormatar(e)
            })
        },
        dialogConteudoEditarAbrir(conteudo) {
            this.registro = {
                ...conteudo
            }
            this.dialogConteudoEditar = true
        },
        dialogFraseAlterarAbrir(frase) {
            this.registro = {
                ...frase,
                id_curso : this.id_curso
            }
            this.dialogFraseEditar = true
        },
        dialogFraseCadastrarAbrir(frase) {
            this.registro = {
                ...frase
            }
            this.dialogFraseCadastrar = true
        },
        dialogLivroAbrir(livro) {
            this.carregando = false
            this.registro = {
                ...livro
            }
            this.dialogLivro = true
        }
    },
    watch : {
        'registro.epub' : function () {
            this.registro.arquivo = null
            this.registro.arquivo_placeholder = null
        }
    },
    activated() {
        this.registro = { status : 't' }
        if(this.id_curso) {
            this.get()
        }
    }
}
</script>

<style scoped>

</style>
